<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">{{ $t('message.updateBundles') }}</v-card-title>
            <v-card-text>
                <v-form lazy-validation>
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.product') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="allProductGroups"
                                :placeholder="$t('message.startTypingToSearch')"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-value="ProductGroup.id"
                                item-text="ProductGroup.title"
                                solo
                                v-model="Stock__productgroup_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.species') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="allSpecies"
                                :placeholder="$t('message.startTypingToSearch')"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-value="Species.id"
                                item-text="Species.title"
                                solo
                                v-model="Stock__species_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.grade') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="allGrades"
                                :placeholder="$t('message.startTypingToSearch')"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Grade.title"
                                item-value="Grade.id"
                                solo
                                v-model="Stock__grade_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.certification') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                :items="allCertifications"
                                :placeholder="$t('message.startTypingToSearch')"
                                :value="Stock__certification_id"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Certification.title"
                                item-value="Certification.id"
                                solo
                                v-model="Stock__certification_id"
                            ></v-select>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.thickness') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row">
                                <v-autocomplete
                                    :items="allThickness"
                                    :placeholder="$t('message.startTypingToSearch')"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Thickness.title"
                                    item-value="Thickness.id"
                                    solo
                                    v-model="Stock__thickness_id"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.width') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row">
                                <v-text-field
                                    :value="Stock__width"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Stock__width = $event"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.length') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row">
                                <v-text-field
                                    :value="Stock__length"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Stock__length = $event"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.save"
                    color="info"
                    small
                    @click="saveBundles()"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {mapFields} from "vuex-map-fields";
    import SimpleAlert from "Components/Appic/SimpleAlert";
    import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";

    export default {
        name: "MassUpdateBundleV2",
        props: ['bundles','dialog','dialog-closed','update-done'],
        data(){
            return {
                dialogs: {
                    error: false,
                    error_message: ""
                },
                loading: {
                    save: false
                },
                edit_dialog: false,
                Stock__certification_id: null,
                Stock__grade_id: null,
                Stock__length: null,
                Stock__productgroup_id: null,
                Stock__species_id: null,
                Stock__thickness_id: null,
                Stock__width: null
            }
        },
        computed: {
            ...mapGetters('certification', {
                allCertifications: 'allCertifications',
            }),
            ...mapGetters('currency', {
                allCurrencies: 'allCurrencies',
            }),
            ...mapGetters('grade',{
                allGrades: 'allGrades',
                findGradeById: 'findGradeById'
            }),
            ...mapGetters('productgroup', {
                allProductGroups: 'allProductGroups',
            }),
            ...mapGetters('species', {
                allSpecies: 'allSpecies',
                findSpeciesById: 'findSpeciesById'
            }),
            ...mapGetters('thickness', {
                allThickness: 'allThickness'
            }),
            ...mapGetters([
                'offices',
                'uofmQty',
                'uofmDimensions'
            ]),
            editDialog: {
                get() {
                    return this.edit_dialog;
                },
                set(value){
                    this.edit_dialog = value
                    if(value == false) {
                        this.$emit('dialog-closed')
                    }
                }
            },
            filteredOffices () {
                return this.offices.filter( o => [1,21].includes(o.Office.id))
            },
            stockOrderCurrency () {
                if(this.Stock__StockOrder__currency_id) {
                    return this.allCurrencies.find(c => c.Currency.id == this.Stock__StockOrder__currency_id)
                } else {
                    return this.allCurrencies.find(c => c.Currency.id == 1)
                }
            }
        },
        methods: {
            ...mapActions('certification', {
                getAllCertifications: 'getAllCertifications'
            }),
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('grade', {
                getAllGrades: 'getAllGrades'
            }),
            ...mapActions('productgroup', {
                getAllProductGroups: 'getAllProductGroups'
            }),
            ...mapActions('species', {
                getAllSpecies: 'getAllSpecies'
            }),
            ...mapActions('stock',{
                updateBundles: 'updateBundles'
            }),
            ...mapActions('thickness', {
                getAllThickness: 'getAllThickness'
            }),
            saveBundles () {
                this.loading.save = true
                let payload = {
                    bundles: this.bundles,
                    fields: {
                        certification_id: this.Stock__certification_id,
                        grade_id: this.Stock__grade_id,
                        length: this.Stock__length,
                        productgroup_id: this.Stock__productgroup_id,
                        species_id: this.Stock__species_id,
                        thickness_id: this.Stock__thickness_id,
                        width: this.Stock__width
                    }
                }
                this.updateBundles(payload)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.bundlesUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.bundlesNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    }).catch(() => {
                        this.$toast.error(this.$t('message.errors.bundlesNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            },
            getUofmDimension( uofm_id ) {
                let uofm = this.uofmDimensions.find(u => u.Measurement.id == uofm_id)?.Measurement.title
                return uofm;
            },
            getUofmQty( uofm_id ) {
                let uofm = this.uofmQty.find(u => u.Measurement.id == uofm_id)?.Measurement.title
                return uofm;
            },
            resetForm () {
                this.Stock__certification_id = null
                this.Stock__grade_id = null
                this.Stock__length = null
                this.Stock__productgroup_id = null
                this.Stock__species_id = null
                this.Stock__thickness_id = null
            }
        },
        watch: {
            dialog(value) {
                if(value == false){
                    this.resetForm()
                }
                this.edit_dialog = value
            }
        },
        created () {
            if(this.allSpecies.length == 0) this.getAllSpecies()
            if(this.allGrades.length == 0) this.getAllGrades()
            if(this.allProductGroups.length == 0) this.getAllProductGroups()
            if(this.allCertifications.length == 0) this.getAllCertifications()
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
            if(this.allThickness.length == 0) this.getAllThickness()
        },
        mounted() {
            this.$store.watch(
                function (state) {
                    return state.appic.stock.update.Stock
                },
                () => {
                    // this.updatedStockOrderUnsaved = true
                },
                {deep: true}
            )
        }
    }
</script>

<style>

</style>